import React from "react";
import { Helmet } from 'react-helmet'
import "./App.scss"
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { LandingForm } from "../../components/landingForm"
import imgLogo from "./assets/img/logo-ucc.webp";
import imgBanner from "./assets/img/hero/mercado-de-capitales.webp";
import imgCalendar from "./assets/img/icons/calendar.webp";
import imgPin from "./assets/img/icons/pin.webp";
import imgReloj from "./assets/img/icons/reloj.webp";
import imgDinero from "./assets/img/icons/dinero.webp";

const MercadoCapitales = () => {
	return (
		<>
			<Helmet>
				<script>
				{`(function(w,d,s,l,i)
					{w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});
					var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
					j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-PLKH83F');`}
				</script>

				<noscript>
				{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PLKH83F"
						height="0" width="0" style="display:none;visibility:hidden"> 
					</iframe>`}
				</noscript>
			</Helmet>
			<Navbar sticky="top" bg="azul" className="navbar-landing">
				<Container
					style={{
						justifyContent: "start",
					}}
				>
					<Navbar.Brand>
						<img
							src={imgLogo}
							width="100%"
							height="100%"
							alt="Diplomatura en mercado de capitales"
						/>
					</Navbar.Brand>
				</Container>
			</Navbar>
			<main>
				<section
					className="hero hero-azul d-flex align-items-center"
					style={{ backgroundImage: `url(${imgBanner})` }}
				>
					<Container className="position-relative">
						<Row>
							<Col className="gap-2 d-flex flex-column">
								<div className="d-flex align-items-center gap-2 gap-md-3">
									<h3
										className="bg-white text-azul fw-light m-0"
										style={{ padding: '0.8rem' }}
									>
										Diplomatura{" "}
										<span className="fw-extrabold">
											Online
										</span>
									</h3>{" "}
									<h4 className="m-0 fw-light text-uppercase text-white">
										Inicio{" "}
										<span className="d-block fw-extrabold">
											21 de abril
										</span>
									</h4>
								</div>
								<h1>Diplomatura en mercado de capitales</h1>
								<h5 className="text-white text-uppercase fw-semibold">
									Primera Universidad Privada de Argentina
								</h5>
								<a
									href="#formulario"
									className="btn btn-hero btn-hero-azul shadow-sm d-flex align-self-center align-self-md-start smooth"
								>
									Inscríbete ahora
								</a>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="descripcion" className="bg-light-landing">
                    <Container>
                        <Row>
                            <Col md={6} className="pt-5 pb-3 py-md-6">
								<h3 className="title text-azul">
									Consúltanos{" "}
									<span className="d-block">
										por este curso
									</span>
								</h3>
								<p className="p-style">
									Programa de capacitación destinado a
									proporcionar al asistente un profundo
									entrenamiento en relación al funcionamiento
									de los mercados de capitales.
								</p>
								<p className="p-style">
									Diseñado y organizado por la Facultad de
									Ciencias Económicas y de Administración.
								</p>
								<p className="p-style">
									Se otorgará certificación digital oficial de
									la Universidad Católica de Córdoba, con
									opción para adquirir la certificación en
									papel.
								</p>

								<h5 className="p-style fw-bold">Docentes:</h5>
								<ul>
									<li>Dra. Tolosa, Leticia Eva (Director)</li>
									<li>Cr. Bosio, Guillermo Miguel (Disertante)</li>
									<li>Cr. Erede, Justo Maximiliano (Disertante)</li>
									<li>Cr. Salomon Chacon, Christian Leonardo (Disertante)</li>
									<li>Espec. Montes, Hector Daniel (Disertante)</li>
									<li>Lic. Daniele Barra, Carla María (Disertante)</li>
									<li>Lic. Econ. Capra, Agustín German (Disertante)</li>
									<li>Lic. Gomez Mancuso, Juan Pablo (Disertante)</li>
									<li>Lic. Lamberghini, Juan Francisco (Disertante)</li>
									<li>Lic. Petrini, Iván (Disertante)</li>
									<li>Lic. Ventre, Fabio Ezequiel (Disertante)</li>
									<li>Lic. Voiron, Carlos Ignacio (Disertante)</li>
									<li>Mag. Rodriguez Machado, Argos (Disertante)</li>
									<li>Mag. Rubio, Gabriel Alejandro (Disertante)</li>
									<li>Mas. Cuello, Gladys Noemi (Disertante)</li>
								</ul>
                            </Col>
                            
                            <Col md={6} className="pt-5 pb-6 py-md-6 px-md-4" id="formulario">       
								<div className='container px-4'>
									<LandingForm nomCurso="Mercado de Capitales"/>
								</div>                       
                            </Col>
                        </Row>
					</Container>
				</section>
				<section className="position-relative">
					<a href="#resume" className="smooth icon-down">
						<FontAwesomeIcon icon={faArrowDown} style={{ fontSize: 40, width: 100 }} className="bi bi-arrow-down-short d-flex justify-content-center pt-2 text-azul"/>						
					</a>
					<Container>
						<Row id="resume" className="justify-content-center">
							<Col
								sm={11}
								className="bg-white rounded-4 shadow-sm mt-n4 mb-5"
								style={{ zIndex: 1 }}
							>
								<Row className="row-cols-2 row-cols-md-4 py-2 py-md-0">
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img
											src={imgCalendar}
											alt="Fecha de inicio"
										/>
										<div>
											<span className="d-block fw-medium">
												21 de Abril
											</span>
										</div>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img src={imgPin} alt="Ubicación" />
										<span className="text-capitalize fw-medium">
											- Campus UCC <br />- Campus Virtual
											UCC
										</span>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img
											src={imgReloj}
											alt="Duración del diplomado"
										/>
										<span className="text-capitalize fw-medium">
											8 meses
											<br />
											120 horas totales.
										</span>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img src={imgDinero} alt="Precio" />
										<span className="text-capitalize fw-medium">
											Público en general <br />
											USD 1039
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="info">
					<Container className=" pt-4 pb-5 pb-md-6">
						<Row className="mb-4">
							<Col className="text-center">
								<h3 className="title text-azul">
									Objetivos del curso
								</h3>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col md={6}>
								{" "}
								<p className="p-style">
									Conocer el marco institucional y los
									aspectos normativos aplicables al mercado de
									capitales.
								</p>
								<p className="p-style">
									Identificar las características de los
									principales productos financieros que pueden
									ser canalizados por intermedio del mercado
									de capitales.
								</p>
								<p className="p-style">
									Aprender herramientas para el análisis de
									operaciones e instrumentos financieros
									propios del mercado de capitales.
								</p>
							</Col>
							<Col md={6}>
								<p className="p-style">
									Desarrollar habilidades en la administración
									de carteras de inversión y en el análisis de
									riesgos.
								</p>
								<p className="p-style">
									Comprender los aspectos éticos y
									deontológicos que deben regir las conductas
									de los participantes del mercado de
									capitales.
								</p>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col className="text-center">
								<h3 className="title text-azul">
									¿A quién está dirigido?
								</h3>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								{" "}
								<p className="p-style">
									Personas que desarrollen actividad de venta,
									promoción o asesoramiento a través de
									agentes registrados en la Comisión Nacional
									de Valores (CNV).
								</p>
								<p className="p-style">
									Personas interesadas en el funcionamiento
									del Mercado de Capitales y en los productos
									financieros que son propios del mismo.
								</p>
							</Col>
							<Col md={6}>
								{" "}
								<p className="p-style">
									Profesionales y estudiantes avanzados
									interesados en las temáticas.
								</p>
								<p className="p-style">
									Docentes de materias específicas y/o
									vinculadas con los Mercados de Capitales.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="programa" className="bg-light-landing py-5 py-md-6">
					<Container>
						<Row>
							<Col className="text-center">
								<h3 class="title text-azul mb-3">Contenidos</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="0"
										>
											Módulo I
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
                                                Matemática y Estadística aplicada a los mercados de capitales (2 clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="1"
										>
											Módulo II
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
											<Card.Body>
                                                El marco normativo del mercado de capitales. Emisoras. Régimen sancionatorio. OPA. Oferta Pública. Categorías de Agentes (2 Clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="2"
										>
											Módulo III
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2">
											<Card.Body>
                                                Aspectos económicos y financieros relevantes (2 Clase).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="3"
										>
											Módulo IV
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="3">
											<Card.Body>
                                                Instrumentos y operaciones que se utilizan en el mercado de capitales.(2 Clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="4"
										>
											Módulo V
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="4">
											<Card.Body>
                                                Mercado de renta fija. Herramientas de análisis para decisiones de inversión y financiamiento (4 Clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="5"
										>
											Módulo VI
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="5">
											<Card.Body>
                                                Mercado de renta variable. Herramientas de análisis para decisiones de inversión y financiamiento Análisis fundamental. (4 Clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="6"
										>
											Módulo VII
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="6">
											<Card.Body>
                                                Futuros, opciones y Swap (4 Clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="7"
										>
											Módulo VIII
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="7">
											<Card.Body>
                                                Análisis Técnico (2 Clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="8"
										>
											Módulo IX
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="8">
											<Card.Body>
                                                Administración de carteras de inversión y análisis del riesgo (2 Clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="9"
										>
											Módulo X
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="9">
											<Card.Body>
                                                Productos de Inversión Colectiva. Fondos Comunes de Inversión. (1 Clase).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="10"
										>
											Módulo XI
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="10">
											<Card.Body>
                                                Informes financieros y su exposición Normas locales e Internacionales – NIIF (1 Clase).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="11"
										>
											Módulo XII
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="11">
											<Card.Body>
                                                Normas de prevención de lavados de activos y financiamiento del terrorismo. (2 Clase).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="12"
										>
											Módulo XIII
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="12">
											<Card.Body>
                                                Ética. Responsabilidad Social Empresaria (RSE). Transparencia. Procedimiento de denuncias, reclamos y consultas. Régimen Informativo. Inversores (2 Clases).
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="13"
										>
											Módulo XIV
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="13">
											<Card.Body>
                                                Aspectos Impositivos de los distintos instrumentos financieros.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="14"
										>
											Actividades auxiliares
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="14">
											<Card.Body>
                                                Simulador que le permitirá experimentar la dinámica de operar en el mercado de capitales de su país.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</Col>
						</Row>
					</Container>
				</section>
			</main>
            <footer class="bg-azul">
                <Container className="py-5">
                    <Row>
                        <Col className="text-center">
                            <h2>Certificación Oficial de la Universidad Católica de Córdoba</h2>
                        </Col>
                    </Row>

                </Container>

    </footer>
		</>
	);
};

export default MercadoCapitales;
